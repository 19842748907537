import * as Sentry from "@sentry/browser";
import { captureConsoleIntegration } from "@sentry/integrations";

import FoodridersConfig from "../src/FoodridersConfig";

if (FoodridersConfig.setupSentry) {
  console.debug(`[FR] Sentry.init enableSentryDebug=${FoodridersConfig.enableSentryDebug} env=${FoodridersConfig.sentryEnvironment}`)

  Sentry.init({
    dsn: FoodridersConfig.sentryDsn,
    environment: FoodridersConfig.sentryEnvironment,
    debug: FoodridersConfig.enableSentryDebug,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    // release: "foodriders@0.1.1",
    integrations: [
      FoodridersConfig.sentryBrowserTracing && Sentry.browserTracingIntegration(),
      FoodridersConfig.sentryReplay && Sentry.replayIntegration(),
      FoodridersConfig.sentryCaptureConsole && captureConsoleIntegration(FoodridersConfig.sentryCaptureConsoleLevels)
    ],

    // ['log', 'info', 'warn', 'error', 'debug', 'assert']
    // browserTracingIntegration(),
    // replayIntegration(),

    maxBreadcrumbs: 30,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.4,
    sampleRate: 0.8,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/foodriders\.co/, /fdrd\.rs/, /nu\.foodriders\.co/, /beta\.foodriders\.co/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
